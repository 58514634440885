.no-scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #dddddd;
    /*box-shadow: inset 0 0 5px #dddddd;*/
    border-radius: 4px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 4px;
  }